import styled from 'styled-components';

enum WidthValues {
  'fluid',
  'page',
  'text',
}

enum AlignValues {
  'left',
  'center',
  'right',
}

interface Props {
  width?: keyof typeof WidthValues;
  align?: keyof typeof AlignValues;
}

const makeWidth = (width: keyof typeof WidthValues) => {
  if (width === 'text') {
    return 'var(--text-width)';
  }
  if (width === 'page') {
    return 'var(--page-width)';
  }
  return '100%';
};

const makeAlign = (align: keyof typeof AlignValues) => {
  if (align === 'center') {
    return '0 auto';
  }
  if (align === 'right') {
    return '0 auto';
  }
  return '';
};

export const ContainerWrapper = styled.div`
  padding: 0 1rem;
  max-width: ${({ width }: Props) => makeWidth(width)};
  margin: ${({ align }: Props) => makeAlign(align)};
`;
