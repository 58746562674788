import React from 'react';
import styled from 'styled-components';
import { SEO } from '../components/SEO';
import { ContainerWrapper } from '../components/styled/Container';
import { UniversalLink } from '../components/UniversalLink';

const HomeHero = styled.div`
  min-height: 30vh;
`;

const BigText = styled.p`
  font-size: var(--font-size-l);
`;

const Index = ({ location }) => {
  return (
    <div>
      <SEO title='Oh hey' />
      <HomeHero />
      <ContainerWrapper width='text' align='center'>
        <BigText>Oh hey! I didn't see you there.</BigText>
        <BigText>
          My name is Gene and I like cool things. Sometimes I{' '}
          <UniversalLink to={'/blog'}>write</UniversalLink> about them,
          sometimes I{' '}
          <UniversalLink to={'https://twitter.com/genehenson'}>
            tweet
          </UniversalLink>{' '}
          about them and sometimes I{' '}
          <UniversalLink to={'https://github.com/genehenson'}>
            make
          </UniversalLink>{' '}
          them.
        </BigText>
      </ContainerWrapper>
    </div>
  );
};

export default Index;
